const translationCopy = [
    {
        name: "siteLoadingTxt",
        location: "global",
        description: "string to show when site is loading",
        en: "Loading",
        fr: "Chargement",
        de: "Laden"
    },
    {
        name: "footLnkCopyRights",
        location: "global",
        description: "footer copyrights string, note that %&year&% will be automatically filled",
        en: "Copyright © %&year&% SwissDonations. All rights reserved",
        fr: "Copyright © %&year&% SwissDonations. Tous droits réservés",
        de: "Copyright © %&year&% SwissDonations"
    },
    {
        name: "footLnkPrivacyPolTxt",
        location: "global",
        description: "privacy policy appelation",
        en: "Privacy policy",
        fr: "Politique de confidentialité",
        de: "Datenschutzerklärung Richtlinie"
    },
    {
        name: "footLnkImpressTxt",
        location: "global",
        description: "impressum appelation",
        en: "Impressum",
        fr: "Mentions légales",
        de: "Impressum"
    },
    {
        name: "footLnkTouTxt",
        location: "global",
        description: "terms of use appelation",
        en: "Terms of use",
        fr: "Conditions d'utilisation",
        de: "Nutzungsbedingungen"
    },
    {
        name: "footLnkFaqTxt",
        location: "global",
        description: "faq applation",
        en: "FAQ",
        fr: "FAQ",
        de: "FAQ"
    },
    {
        name: "siteLoginBtnTxt",
        location: "global",
        description: "call to login button string",
        en: "Sign in",
        fr: "Connexion",
        de: "Anmelden"
    },
    {
        name: "siteLogOutBtnTxt",
        location: "global",
        description: "call to login button string",
        en: "Logout",
        fr: "Se déconnecter",
        de: "Abmelden"
    },
    {
        name: "myAccountBtnTxt",
        location: "global",
        description: "call to login button string",
        en: "My account",
        fr: "Mon compte",
        de: "Mein Konto"
    },
    {
        name: "createAccBtnTxt",
        location: "home page",
        description: "call to create account on home page",
        en: "Sign up",
        fr: "Créer un compte",
        de: "Registrieren"
    },
    {
        name: "toFundraisersBtnTxt",
        location: "home page",
        description: "call to go to fundraisers page",
        en: "All our fundraising campaigns",
        fr: "Toutes nos collectes de fonds",
        de: "Alle unsere Spendenaktionen"
    },
    {
        name: "toOrganisationBtnTxt",
        location: "home page",
        description: "call to go to organizations page",
        en: "All assocations",
        fr: "Toutes nos associations",
        de: "Alle unsere NPO"
    },
    {
        name: "learnMoreBtnTxt",
        location: "home page, usable elsewhere",
        description: "call to learn more",
        en: "Learn more",
        fr: "En savoir plus",
        de: "Mehr erfahren"
    },
    {
        name: "homeSignUpDonationBtnTxt",
        location: "home page",
        description: "call to signup under direct donation",
        en: "Get started",
        fr: "Créer un compte",
        de: "Registrieren"
    },
    {
        name: "homeSignupSavingsBtnTxt",
        location: "home page",
        description: "call to signup under donations saving account",
        en: "Get started",
        fr: "Créer un compte",
        de: "Registrieren"
    },
    {
        name: "detailsBtnTxt",
        location: "multiple",
        description: "call to view details of item",
        en: "More",
        fr: "Détails",
        de: "Mehr"
    },
    {
        name: "menuAccountHomeTxt",
        location: "menu",
        description: "menu link",
        en: "Overview",
        fr: "Mon compte",
        de: "Übersicht"
    },
    {//
        name: "menuActivateAccountTxt",
        location: "menu",
        description: "menu link",
        en: "Activate account",
        fr: "Activer mon compte",
        de: "Konto aktivieren"
    },
    {//
        name: "menuGuestAccountTxt",
        location: "menu",
        description: "menu link",
        en: "Guest account",
        fr: "Compte invité",
        de: "Gastkonto"
    },
    {
        name: "menuAccountDonateTxt",
        location: "menu",
        description: "menu link",
        en: "Donate",
        fr: "Donner",
        de: "Spenden"
    },
    {
        name: "menuAccountFundraiseTxt",
        location: "menu",
        description: "menu link",
        en: "Fundraise",
        fr: "Collectes de fonds",
        de: "Fundraiser"
    },
    {
        name: "menuAccountCharitiesTxt",
        location: "menu",
        description: "menu link",
        en: "Charities",
        fr: "Associations",
        de: "NPO"
    },
    {//new
        name: "menuAccountThemeFondsTxt",
        location: "menu",
        description: "menu link",
        fr: "Fonds thématiques",
        en: "Thematic funds",
        de: "Themenfonds"

    },
    {
        name: "menuAccountHistoryTxt",
        location: "menu",
        description: "menu link",
        en: "History",
        fr: "Historique",
        de: "Meine Spenden"
    },
    {//new
        name: "menuAccountSwNewsTxt",
        location: "menu",
        description: "menu link",
        en: "SwissDonations News",
        fr: "Fil d'actualité SwissDonations",
        de: "SwissDonations Nachrichten"
    },
    {//new
        name: "menuAccountNewsTxt",
        location: "menu",
        description: "menu link",
        en: "News",
        fr: "Actualité",
        de: "Nachrichten"
    },
    {
        name: "menuAccountAboutTxt",
        location: "menu",
        description: "menu link",
        en: "About",
        fr: "À propos",
        de: "Über uns"
    },
    {
        name: "menuAccountFaqTxt",
        location: "menu",
        description: "menu link",
        en: "FAQ",
        fr: "FAQ",
        de: "FAQ"
    }, {// onboarading text
        name: "onBoardingStartTitle",
        location: "payment page not sighned in",
        description: "",
        en: "You are not signed in.",
        fr: "Vous n'êtes pas connecté.",
        de: "Sie sind nicht angemeldet.",
    }, {
        name: "onBoardingStartMessage",
        location: "payment page not sighned in",
        description: "",
        en: "Please sign in, create an account or select our guest checkout option to proceed.",
        fr: "Veuillez vous connecter, créer un compte ou utiliser notre option invité pour continuer.",
        de: "o	Bitte melden Sie sich an, erstellen Sie ein Konto oder wählen Sie unsere Gast-Kassenoption, um fortzufahren.",
    }, {
        name: "onBoardingStartSignUpBtnText",
        location: "payment page not sighned in",
        description: "",
        en: "Sign up",
        fr: "Créer un compte",
        de: "Registrieren",
    }, {
        name: "onBoardingStartAsGuestBtnText",
        location: "payment page not sighned in",
        description: "",
        en: "Checkout as guest",
        fr: "Donner en tant qu'invité",
        de: "Kasse als Gast",
    }, {
        name: "onBoardingStartLoginBtnText",
        location: "payment page not sighned in",
        description: "",
        en: "I already have an account",
        fr: "J'ai déjà un compte",
        de: "Ich habe bereits ein Konto",
    }, {
        name: "onBoardingInfoTitle",
        location: "payment page not sighned in",
        description: "",
        en: "Guest checkout",
        fr: "Donné en tant qu'invité",
        de: "Kasse als Gast",
    }, {// updated
        name: "onBoardingSubmitBtnTxt",
        location: "payment page not sighned in",
        description: "",
        en: "Continue",
        fr: "Continuer",
        de: "Fortfahren",
    }, {
        name: "onBoardingVerifyCodeMessage",
        location: "payment page not sighned in",
        description: "",
        en: "An email was sent to the address you provided, please type the verification code or follow the instruction in the email.",
        fr: "Un email vous a été envoyé à l'adresse fournie, veuillez entrer le code de validation ou suivre les instructions dans l'email.",
        de: "Eine E-Mail wurde an die von Ihnen angegebene Adresse gesendet. Bitte geben Sie den Bestätigungscode ein oder folgen Sie den Anweisungen in der E-Mail.",
    },
    {//
        name: "onBoardingVerifyCodeFormat",
        location: "payment page not signed in",
        description: "Ask for specific code format",
        en: "6 digit number",
        fr: "numéro à 6 chiffres",
        de: "6-stellige Zahl",
    },
    {//new
        name: "onBoardingVerifyCodeError",
        location: "payment page not signed in",
        description: "Error message displayed when the confirmation code provided is invalid.",
        en: "We could not confirm the code you provided. Please revise it and try again.",
        fr: "Nous n'avons pas pu confirmer le code que vous avez fourni. Veuillez le vérifier et réessayer.",
        de: "Wir konnten den von Ihnen eingegebenen Code nicht bestätigen. Bitte überprüfen Sie ihn und versuchen Sie es erneut."
    }
    ,
    {
        name: "onBoardingResendBtnTxt",
        location: "payment page not sighned in",
        description: "",
        en: "Resend code",
        fr: "Renvoyer le code",
        de: "Code erneut senden",
    }, {
        name: "onBoardingCompleteBtnTxt",
        location: "payment page not sighned in",
        description: "",
        en: "Submit",
        fr: "Envoyer",
        de: "Einreichen",
    },
    {
        name: "onBoardingCancelText",
        location: "payment page not signed in",
        description: "cancel button",
        en: "Cancel",
        fr: "Annuler",
        de: "Abbrechen"
    },
    {//
        name: "accountHomeWelcomeTxt",
        location: "overview page",
        description: "welcome string",
        en: "Hi %%name%%, welcome to your SwissDonations account",
        fr: "Bonjour %%name%%, bienvenue sur votre compte SwissDonations",
        de: "Hallo %%name%%, Willkommen auf Ihrem SwissDonations Konto"
    },
    {
        name: "accountHomePrimaryActionTxt",
        location: "overview page",
        description: "Donate button string",
        en: "Donate",
        fr: "Donner",
        de: "Spenden"
    },
    {
        name: "accountHomeCreditStatement",
        location: "overview page",
        description: "user credit display string",
        en: "You have CHF %%amount%% in your account.",
        fr: "Votre compte est crédité de CHF %%amount%%",
        de: "Sie haben CHF %%amount%% auf Ihrem Konto"
    },
    {
        name: "accountHomeSummaryTitle",
        location: "overview page",
        description: "section title",
        en: "Activity Summary",
        fr: "Résumé des activités",
        de: "Zusammenfassung der Aktivitäten"
    },
    {
        name: "accountHomeBarchatTitle",
        location: "overview page",
        description: "barchart title",
        en: "Monthly donations",
        fr: "Dons mensuels",
        de: "Monatliche Spende"
    },
    {
        name: "accountHomePieChartTitle",
        location: "overview page",
        description: "piechart title",
        en: "Donations per charity",
        fr: "Dons par association",
        de: "Spende pro NPO"
    },
    {
        name: "accountHomeFeedTitle",
        location: "overview page",
        description: "section title",
        en: "Charity News",
        fr: "Fil d'info des associations",
        de: "Vereinsnachrichten"
    },
    {
        name: "donateTitle",
        location: "Donate home view",
        description: "title",
        en: "Donate",
        fr: "Donner",
        de: "Spenden"
    },
    {//edited
        name: "donateDirectGroupTitle",
        location: "Donate home view",
        description: "button group title",
        en: "Donate to a charity",
        fr: "Donner à une association",
        de: "Spenden"
    },

    {//not used -> delete
        name: "donateCredtGroupTitle",
        location: "Donate home view",
        description: "button group title",
        en: "Use my credits",
        fr: "Utiliser mes crédits",
        de: "Mein Guthaben ausgeben"
    },
    {
        name: "donateFundraiseGroupTitle",
        location: "Donate home view",
        description: "button group title",
        en: "Fundraise",
        fr: "Collecter des fonds",
        de: "Spenden sammeln"
    }
    ,
    {//not used on donate page
        name: "donateLoadGroupTitle",
        location: "Donate home view",
        description: "button group title",
        en: "Credit my account",
        fr: "Créditer mon compte",
        de: "Meinem Konto gutschreiben"
    },
    {// note used on donate page
        name: "donateDonateBtnTxt",
        location: "Donate home view",
        description: "button group button string",
        en: "Donate",
        fr: "Donner",
        de: "Spenden"
    },
    {//not used on donate page
        name: "donateLoadBtnTxt",
        location: "Donate home view",
        description: "button group button string",
        en: "Load",
        fr: "Recharger",
        de: "Laden"
    },
    {//note use on donate page
        name: "donateFundraiseBtnTxt",
        location: "Donate home view",
        description: "button group button string",
        en: "Fundraise",
        fr: "Collecter",
        de: "Spenden sammeln"
    },
    {
        name: "fundraiseTitle",
        location: "fundraise view",
        description: "title",
        en: "fundraise",
        fr: "Collectes de fonds",
        de: "Fundraiser"
    },
    {
        name: "fundraiseUserSectTitle",
        location: "fundraise",
        description: "section button",
        en: "my fundraisers",
        fr: "Mes collectes",
        de: "Meine Fundraiser"
    },
    {
        name: "fundraiseAllSectTitle",
        location: "fundraise",
        description: "section button",
        en: "all",
        fr: "Toutes les collectes",
        de: "Andreres Fundraiserprojekte"
    },
    {
        name: "fundraiseCreateTitle",
        location: "fundraise",
        description: "create button",
        en: "new",
        fr: "Nouvelle collecte",
        de: "Neues Fundraiserprojekt"
    },
    {
        name: "fundraiseCompletedTxt",
        location: "user fundraisers",
        description: "text indicating that the fundraiser is completed",
        en: "Ended",
        fr: "Terminé",
        de: "Beendet"
    },
    {
        name: "charitiesTitle",
        location: "charities",
        description: "title",
        en: "Charities",
        fr: "Associations",
        de: "Non-Profit-Organisationen"
    },
    {//new
        name: "charityDetailPageSidebarLabel",
        location: "sidebar",
        description: "",
        en: "Fundraising",
        fr: "Collectes de fonds",
        de: "Fundraiser"
    },
    {
        name: "charityPageThemeSelectLabel",
        location: "charities",
        description: "filter label",
        en: "Themes",
        fr: "Thématique",
        de: "Bereich"
    },
    {
        name: "charityPageLabelSelectLabel",
        location: "charities",
        description: "filter label",
        en: "Label",
        fr: "Label",
        de: "Gütesiegel"
    },
    {
        name: "charityPageLocationSelectLabel",
        location: "charities",
        description: "filter label",
        en: "Location of action",
        fr: "Lieu d'action",
        de: "Wo helfen"
    },
    {
        name: "charityDetailPrimary",
        location: "charity detail view",
        description: "primary button string",
        en: "Donate",
        fr: "Donner",
        de: "Spenden"
    },
    {
        name: "charityDetailPrimaryAlt",
        location: "charity detail view",
        description: "primary button string",
        en: "Use my credits",
        fr: "utiliser mes crédits",
        de: "Mein Guthaben nutzen"
    },
    {
        name: "historyTitle",
        location: "history",
        description: "title",
        en: "History",
        fr: "Mon historique",
        de: "Meine Spenden"
    },
    {
        name: "historyDateTitle",
        location: "history",
        description: "column title",
        en: "Date",
        fr: "Date",
        de: "Datum"
    },
    {
        name: "historyBeneficiaryTitle",
        location: "history",
        description: "column title",
        en: "Beneficiary",
        fr: "Destinataire",
        de: "Empfänger"
    },
    {
        name: "historyAmountTitle",
        location: "history",
        description: "column title",
        en: "Amount",
        fr: "Montant",
        de: "Betrag"
    },
    {
        name: "historyTipTitle",
        location: "history",
        description: "column title",
        en: "Tip",
        fr: "Pourboire",
        de: "Trinkgeld"
    },
    {
        name: "historyStatusTitle",
        location: "history",
        description: "column title",
        en: "Status",
        fr: "Statut",
        de: "Status"
    },
    {// new
        name: "historyTransactionMethodTitle",
        location: "history",
        description: "column title",
        en: "Method",
        fr: "Moyen",
        de: "Methode"
    },
    {// new
        name: "historyNoTransactionsText",
        location: "history",
        description: "Non transactions to show text",
        en: "There are no corresponding transactions to show.",
        fr: "Il n’y a pas de transactions correspondantes à afficher.",
        de: "Es gibt keine entsprechenden Transaktionen, die auf Deutsch und Deutsch angezeigt werden können."
    },
    {
        name: "aboutTitle",
        location: "about",
        description: "title",
        en: "About SwissDonations",
        fr: "À propos de nous",
        de: "Über uns"
    },
    {
        name: "faqTitle",
        location: "faq",
        description: "title",
        en: "Frequently Asked Questions",
        fr: "FAQ",
        de: "FAQ"
    },
    {
        name: "donateDirectlyStartTitle",
        location: "donate directly start view",
        description: "title",
        en: "Make a donation",
        fr: "Faire un don",
        de: "Machen Sie eine Spende"
    },
    {
        name: "donateDirectlyStartAmountLabel",
        location: "donate directly start view",
        description: "amount input label",
        en: "How much would you like to donate?",
        fr: "Combien voulez-vous donner ?",
        de: "Wieviel möchten Sie spenden?"
    },
    {
        name: "donateDirectlyStartAmountPlh",
        location: "donate directly start view",
        description: "amount placeholder",
        en: "Amount",
        fr: "Montant",
        de: "Betrag"
    },
    {//new
        name: "donateFreeAmountLabel",
        location: "amount selection forms",
        description: "Free amount label",
        en: "Other amount",
        fr: "Montant libre",
        de: "Anderer Betrag"
    },

    {
        name: "donateDirectlyStartBeneficiaryLabel",
        location: "donate directly start view",
        description: "beneficiary input label",
        en: "To which charity would you like to make this donation?",
        fr: "A quelle association voulez-vous donner ?",
        de: "Für welche NPO möchten Sie spenden?"
    },
    {
        name: "donateDirectlyStartBeneficiaryInputOpt",
        location: "donate directly start view",
        description: "beneficiary input option",
        en: "Other",
        fr: "Autre",
        de: "Andere"
    },
    {
        name: "donateDirectlyStartBeneficiaryConfirm",
        location: "donate directly start view",
        description: "beneficiary input confirm string",
        en: "Ok",
        fr: "OK",
        de: "Ok"
    },
    {
        name: "donateDirectlyStartBeneficiaryCancel",
        location: "donate directly start view",
        description: "beneficiary input cancel string",
        en: "Cancel",
        fr: "Annuler",
        de: "Abbrechen"
    },
    {
        name: "donateDirectlyStartBeneficiarySuggest",
        location: "donate directly start view",
        description: "alt beneficiary label",
        en: "The charity you are looking for is not on our list?",
        fr: "L'association que vous cherchez n'est pas sur la liste ?",
        de: "Die NPO, für die Sie sich interessieren, steht nicht auf der Liste?"
    },
    {
        name: "donateDirectlyStartBeneficiarySuggestPlh",
        location: "donate directly start view",
        description: "alt beneficiary placeholder",
        en: "Please provide us the name of the charity",
        fr: "Ecrivez le nom de l'association",
        de: "Bitte geben Sie den Namen der NPO an"
    },
    {//new
        name: "donateGiftLabel",
        location: "donate to Org form View",
        description: "make a gift label",
        en: "Make a gift to:",
        fr: "Faire un cadeau à :",
        de: "Machen Sie ein Geschenk an:"
    },
    {
        name: "donateGiftDestLabel",
        location: "donate to Org form View",
        description: "make a gift label",
        en: "In favor of:",
        fr: "En faveur de :",
        de: "Zu Gunsten von:"
    }
    ,
    {
        name: "donateDirectlyStartAnonymousCheck",
        location: "donate directly start view",
        description: "anoymous donation option",
        en: "I would like to make this donation anonymous",
        fr: "Je souhaite faire un don anonyme",
        de: "Ich möchte anonym spenden"
    },
    {
        name: "donateDirectlyStartAnonymousUncheck",
        location: "donate directly start view",
        description: "anonymous donation option",
        en: "I consent to my data being shared with the beneficiary organization",
        fr: "Je consens à ce que mes données soient transmises à l'association bénéficiaire",
        de: "Ich stimme zu, dass meine Daten an die begünstigte Organisation weitergegeben werden"
    },
    {
        name: "donateDirectlyStartSubmitTxt",
        location: "donate directly start view",
        description: "submit button string",
        en: "Donate",
        fr: "Donner",
        de: "Spenden"
    },
    {
        name: "donateDirectlyPayTitle",
        location: "donate directly pay view",
        description: "title",
        en: "Amount to donate: CHF %%amount%%",
        fr: "Montant à donner : CHF %%amount%%",
        de: "Betrag der Spende wählen: CHF %%amount%%"
    },
    {
        name: "donateDirectlyPayTotal",
        location: "donate directly pay view",
        description: "total with fees and tip string",
        en: "Total with transfer tip: CHF %%amount%%",
        fr: "Total avec pourboire : CHF %%amount%%",
        de: "Gesamtbetrag inklusive Trinkgeld: CHF %%amount%%"
    },

    {
        name: "SwFundraiserdonateDirectlyPayTotal",
        location: "donate directly pay view",
        description: "total with fees and tip string",
        en: "Total with transfer fee: CHF %%amount%%",
        fr: "Total avec frais : CHF %%amount%%",
        de: "Gesamtbetrag inklusive Transfergebühr: CHF %%amount%%"
    },
    {
        name: "donateDirectlyPayMethodSelect",
        location: "donate directly pay view",
        description: "payment method section title",
        en: "Select a payment method",
        fr: "Choisir une méthode de paiement",
        de: "Zahlungsmittel wählen"
    },
    {
        // to confirm
        name: "donateDirectlyPayMethodSelectAlt",
        location: "donate directly pay view",
        description: "Use your SwissDonations credit",
        en: "Or use your SwissDonations credit",
        fr: "Ou utilisez votre crédit SwissDonations",
        de: "Oder verwenden Sie Ihr SwissDonations-Guthaben"
    }
    ,
    {

        name: "donateDirectlyPayFeeNotice",
        location: "donate directly pay view",
        en: "SwissDonations covers the transaction fees so that 100% of your donation goes to the charity",
        fr: "SwissDonations prend en charge les frais de transaction afin que 100% de votre don aille à l'association",
        de: "SwissDonations übernimmt die  Transaktionsgebühr , so dass 100% Ihrer Spende der Hilfsorganisation zugute kommt."

    },
    {
        name: "donateDirectlyPayFeeTxt",
        location: "donate directly pay view",
        description: "payment fee string",
        en: "Payment fee: ",
        fr: "Frais de paiement : ",
        de: "Transaktionsgebühr:"
    },
    {//new
        name: "donateUseMyCreditPayFeeTxt",
        location: "donate directly pay view",
        description: "payment fee string",
        en: "Payment fee: ",
        fr: "Frais de paiement : ",
        de: "Transaktionsgebühr:"
    },
    {
        //new
        name: "donateUseMyCreditPayMethodNameTxt",
        location: "donate directly pay view",
        description: "Use Sw Account credit title",
        en: "Your SwissDonations Credit",
        fr: "Votre crédit SwissDonations ",
        de: "Ihr SwissDonations-Guthaben "
    },

    {
        name: "donateDirectlyPaySubtotalText",
        location: "donate directly pay view, credit account pay view, donate to fundraiser pay view ",
        description: "fee request string",
        en: "Subtotal with tip:",
        fr: "Sous-total avec pourboire :",
        de: "Gesamtbetrag inklusive Trinkgeld:"
    },
    {
        name: "donateDirectlyPayTipRequest",
        location: "donate directly pay view",
        description: "fee request string",
        en: "Support SwissDonations with a tip and help us keep this platform free.",
        fr: "Soutenez SwissDonations avec un pourboire pour nous aider à garder notre plateforme gratuite.",
        de: "Unterstützen Sie SwissDonations mit einem Trinkgeld und helfen Sie uns, diese Plattform kostenlos zu halten."

    },
    {//new
        name: "donateDirectlyPayTipRequestMotivation",
        location: "Wherever there is a tip request",
        description: "Motivate tip value",
        en: "Preferred option to help us cover our costs.",
        fr: "Option préférée pour couvrir nos frais.",
        de: "Bevorzugte Option, um unsere Kosten zu decken."
    },
    {//*
        name: "termsPrivacyConsent",
        location: "multiple",
        description: "users terms and conditions agreement statement",
        en: {
            label: "By ticking this box",
            opening: "I confirm I accept ",
            connector: " and ",
            terms: "the terms of use",
            confid: "the privacy policy",
            closing: ""
        },
        fr: {
            label: "En cochant cette case",
            opening: "Je confirme accepter ",
            connector: " et ",
            terms: "les conditions d'utilisation",
            confid: "la politique de confidentialité",
            closing: ""
        },
        de: {
            label: "Durch Ankreuzen dieses Kästchens",
            opening: "Ich bestätige, dass ich ",
            connector: " und ",
            terms: "die Nutzungsbedingungen",
            confid: "die Datenschutzrichtlinie",
            closing: " akzeptiere"
        }

    },
    {//*
        name: "donateDirectlypayConfirm",
        location: "donate directly pay view",
        description: "payment method section title",
        en: "I confirm I accept the terms of use",
        fr: "Je confirme accepter les conditions d'utilisation",
        de: "Ja, ich stimme den Nutzungsbedingungen zu"
    },
    {
        name: "donateDirectlypayPrimary",
        location: "donate directly pay view",
        description: "payment button",
        en: "Donate",
        fr: "Donner",
        de: "Spenden"
    },
    {
        name: "donateUseCreditStartTitle",
        location: "donate use credit start view",
        description: "title",
        en: "Use my credits",
        fr: "Utiliser mes crédits",
        de: "Mein Guthaben ausgeben"
    },
    {
        name: "donateUseCreditStartIndication",
        location: "donate use credit start view",
        description: "current credit indication",
        en: "Current account credits: CHF %%amount%%",
        fr: "Crédits actuels : CHF %%amount%%",
        de: "Mein Guthaben: CHF %%amount%%"
    },
    {
        name: "donateUseCreditStartAmountLabel",
        location: "donate use credit start view",
        description: "amount input label",
        en: "How much would you like to donate?",
        fr: "Combien voulez-vous donner ?",
        de: "Wieviel möchten Sie spenden?"
    },
    {
        name: "donateUseCreditStartPrimaryTxt",
        location: "donate use credit start view",
        description: "primary button string",
        en: "Use credits",
        fr: "Utiliser les crédits",
        de: "Mein Guthaben ausgeben"
    },
    {
        name: "donateUseCreditStartSecondaryTxt",
        location: "donate use credit start view",
        description: "secondary button string",
        en: "Donate",
        fr: "Donner",
        de: "Spenden"
    },

    {// also used for fundraiseDonateStart
        name: "donateUseCreditStartInsufficientCr",
        location: "donate use credit start view",
        en: "Your credit is lower than the specified amount",
        fr: "Votre crédit est insuffisant",
        de: "Ihr Guthaben reicht nicht aus"
    },
    {//also use for donateUseCreditStart
        name: "donateUseCreditAltTxt",
        location: "donate use credit start view",
        description: "alternative button string",
        en: "Credit account",
        fr: "Créditer mon compte",
        de: "Meinem Konto gutschreiben"
    },
    {
        name: "donateUseCreditDetailsAmountLabel",
        location: "donate use credit details view",
        description: "amount label",
        en: "How much would you like to donate?",
        fr: "Combien voulez-vous donner ?",
        de: "Wieviel möchten Sie spenden?"
    },
    {
        name: "donateUseCreditDetailsAmountLPhl",
        location: "donate use credit details view",
        description: "amount placeholder",
        en: "Amount",
        fr: "Montant",
        de: "Betrag"
    },
    {
        name: "donateUseCreditDetailsBenLabel",
        location: "donate use credit details view",
        description: "beneficiary input label",
        en: "To which charity would you like to make this donation?",
        fr: "A quelle association voulez-vous donner ?",
        de: "Für welche NPO möchten Sie spenden?"
    },
    {
        name: "donateUseCreditDetailsAltBeneficiaryPlh",
        location: "donate use credit details view",
        description: "alt beneficiary placeholder",
        en: "Please provide us a name",
        fr: "Ecrivez le nom de l'association",
        de: "Bitte geben Sie den Namen der NPO an"
    },
    {
        name: "donateUseCreditDetailsBeneficiaryOpt",
        location: "donate use credit details view",
        description: "beneficiary input option",
        en: "Other",
        fr: "Autre",
        de: "Andere"
    },
    {
        name: "donateUseCreditDetailsBeneficiaryConfirm",
        location: "donate use credit details view",
        description: "beneficiary input confirm string",
        en: "OK",
        fr: "OK",
        de: "Ok"
    },
    {
        name: "donateUseCreditDetailsBeneficiaryCancel",
        location: "donate use credit details view",
        description: "beneficiary input cancel string",
        en: "Cancel",
        fr: "Annuler",
        de: "Abbrechen"
    },
    {
        name: "donateUseCreditDetailsBeneficiaryAltLabel",
        location: "donate use credit details view",
        description: "alt beneficiary label",
        en: "The charity you are looking for is not on our list?",
        fr: "L'association que vous cherchez n'est pas sur la liste ?",
        de: "Die NPO, für die Sie sich interessieren, steht nicht auf der Liste?"
    },
    {
        name: "donateUseCreditDetailsAnonymousCheck",
        location: "donate use credit details view",
        description: "anoymous donation option",
        en: "I would like to make this donation anonymous",
        fr: "Je souhaite faire un don anonyme",
        de: "Ich möchte anonym spenden"
    },
    {
        name: "donateUseCreditDetailsFrequencyTitle",
        location: "donate use credit details view",
        description: "frequency label",
        en: "Would you like to make this donation:",
        fr: "Souhaitez-vous faire ce don :",
        de: "Frequenz der Spende wählen"
    },
    {
        name: "donateUseCreditDetailsSingle",
        location: "donate use credit details view",
        description: "frequency option single",
        en: "Only once",
        fr: "Une seule fois",
        de: "Einmalig"
    },
    {
        name: "donateUseCreditDetailsMonthly",
        location: "donate use credit details view",
        description: "frequency option recurring",
        en: "Monthly",
        fr: "Mensuel",
        de: "Monatlich"
    },
    {
        name: "donateUseCreditDetailsExecuteLabel",
        location: "donate use credit details view",
        description: "executon date input",
        en: "When would you like us to execute this donation?",
        fr: "Quand souhaitez-vous faire ce don ?",
        de: "Wann möchten Sie Ihre Spende machen?"
    },
    {
        name: "donateUseCreditDetailsExecuteClear",
        location: "donate use credit details view",
        description: "executon date input clear",
        en: "Clear",
        fr: "Effacer",
        de: "Löschen"
    },
    {
        name: "donateUseCreditDetailsExecuteCancel",
        location: "donate use credit details view",
        description: "executon date input clear",
        en: "Cancel",
        fr: "Annuler",
        de: "Abbrechen"
    },
    {
        name: "donateUseCreditDetailsExecuteConfirm",
        location: "donate use credit details view",
        description: "executon date input clear",
        en: "Ok",
        fr: "Ok",
        de: "Ok"
    },
    {
        name: "donateUseCreditDetailsNext",
        location: "donate use credit details view",
        description: "progress to next step button",
        en: "Next",
        fr: "Suivant",
        de: "Weiter"
    },
    {//*
        name: "donateUseCreditDetailsTermsConfrim",
        location: "donate use credit details view",
        en: "I confirm I accept the terms of use",
        fr: "Je confirme accepter les conditions d'utilisation",
        de: "Ja, ich stimme den Nutzungsbedingungen zu"
    },
    {
        name: "donateUseCreditDetailsPrimary",
        location: "donate use credit details view",
        description: "form submit button",
        en: "Donate",
        fr: "Donner",
        de: "Spenden"
    },
    {
        name: "donateUseCreditDetailsSuccess",
        location: "donate use credit details view",
        description: "confirmation popup string ",
        en: "Your donation was successfully submitted",
        fr: "Votre demande de don a été envoyée",
        de: "Ihre Spende war erfolgreich"
    },
    {
        name: "donateUseCreditDetailsError",
        location: "donate use credit details view",
        description: "confirmation popup string ",
        en: "Oops, something went wrong, please try again. If this issue persists, please help us improve our user experience by informing us about it.",
        fr: "Oops, une erreur est survenue, veuillez réessayer. Si le problème persiste, veuillez nous aider à améliorer notre expérience utilisateur en nous en informant.",
        de: "Ups, etwas ist schief gelaufen, bitte versuchen Sie es erneut.   Wenn dieses Problem weiterhin besteht, helfen Sie uns bitte, unsere Benutzerfreundlichkeit zu verbessern, indem Sie uns darüber informieren.",
    },
    {
        name: "donatePayCriticalError",
        location: "donate use credit details view",
        description: "confirmation popup string ",
        en: "A critical error has occurred with your transaction. Please try again or click on **Get Help** if the issue persists. We apologize for the inconvenience and appreciate your patience.",
        fr: "Une erreur critique s’est produite avec votre transaction. Veuillez réessayer ou cliquez sur Obtenir de l’aide si le problème persiste. Nous nous excusons pour le désagrément et vous remercions de votre patience.",
        de: "Ein kritischer Fehler ist bei Ihrer Transaktion aufgetreten. Bitte versuchen Sie es erneut oder klicken Sie auf Hilfe bekommen, wenn das Problem weiterhin besteht. Wir entschuldigen uns für die Unannehmlichkeiten und danken Ihnen für Ihre Geduld.",
    },
    {
        name: "donateUseCreditDetailsProcessing",
        location: "donate use credit details view",
        description: "confirmation popup string ",
        en: "Please hold on, we are currently processing your donation",
        fr: "Veuillez patienter, nous traitons actuellement votre don.",
        de: "Bitte warten Sie, wir verarbeiten gerade Ihre Spende."
    },
    {
        name: "donateUseCreditDetailsDoneString",
        location: "donate use credit details view",
        description: "confirmation popup string ",
        en: "Done",
        fr: "Terminer",
        de: "Fertig"
    },
    {
        name: "donateUseCreditDetailsTryAgainString",
        location: "donate use credit details view",
        description: "confirmation popup string ",
        en: "Try again",
        fr: "Réessayez",
        de: "Versuchen Sie es erneut"
    },
    {
        name: "creditAccountStartTitle",
        location: "credit account start view",
        description: "title",
        en: "Credit account",
        fr: "Créditer mon compte",
        de: "Meinem Konto gutschreiben"
    },
    {
        name: "creditAccountStartAmountLabel",
        location: "credit account start view",
        description: "amount input label",
        en: "How much would you like to add?",
        fr: "Combien souhaitez-vous ajouter sur votre compte ?",
        de: "Wieviel möchten Sie auf Ihr Konto einzahlen?"
    },
    {
        name: "creditAccountStartAmountPlh",
        location: "credit account start view",
        description: "amount input placeholder",
        en: "Amount in CHF",
        fr: "Montant en CHF",
        de: "Betrag"
    },
    {
        name: "creditAccountStartPrimary",
        location: "credit account start view",
        description: "primary button string",
        en: "credit account",
        fr: "Créditer mon compte",
        de: "Meinem Konto gutschreiben"
    },
    {// also use for swFundraiser
        name: "creditAccountStartSecondary",
        location: "credit account start view",
        description: "secondary button string",
        en: "or use bank transfer",
        fr: "Ou effectuer un virement bancaire",
        de: "Oder per Banküberweisung"
    },
    {
        name: "creditAccountPaymentTitle",
        location: "credit account payment view",
        description: "title",
        en: "Amount to credit: %%amount%%",
        fr: "Montant à créditer : CHF %%amount%%",
        de: "Betrag umzu gutgeschrieben: CHF %%amount%%"
    },
    {
        name: "creditAccountPaymentPrimary",
        location: "credit account payment view",
        description: "primary button string",
        en: "credit account",
        fr: "Créditer mon compte",
        de: "Meinem Konto gutschreiben"
    },
    {
        name: "fundraiseDetailProgress",
        location: "fundraise detail view",
        description: "progress string",
        en: " out of %%target%% CHF raised",
        fr: " sur %%target%% CHF récoltés",
        de: " von %%target%% CHF gesammelt"
    },
    {
        name: "fundraiseDetailProgressNoTarget",
        location: "fundraise detail view",
        description: "progress string",
        en: "  raised",
        fr: "  récoltés",
        de: "  gesammelt"
    },
    {
        name: "fundraiseDetailDeadline",
        location: "fundraise detail view",
        description: "deadline string",
        en: "Ending on",
        fr: "La collecte prend fin le",
        de: "Enddatum"
    },
    {
        name: "fundraiseDetailPrimary",
        location: "fundraise detail view",
        description: "primary button string",
        en: "Donate",
        fr: "Donner",
        de: "Spenden"
    },
    {
        name: "fundraiseDetailPrimaryAlt",
        location: "fundraise detail view",
        description: "primary button string",
        en: "Use my credits",
        fr: "Utiliser mes crédits",
        de: "Mein Guthaben nutzen"
    },
    {
        name: "fundraiseDonateStartTitle",
        location: "fundraise donate start view",
        description: "indication of beneficiary in title ",
        en: "Donate to: %%title%%",
        fr: "Donner à : %%title%%",
        de: "Spenden an: %%title%%"
    },
    {
        name: "fundraiseDonateStartCreditIndication",
        location: "fundraise donate start view",
        description: "credit indicator",
        en: "Current account credits: CHF %%amount%%",
        fr: "Crédits actuels : CHF %%amount%%",
        de: "Mein Guthaben: CHF %%amount%%"
    },
    {
        name: "fundraiseDonateStartAmountLabel",
        location: "fundraise donate start view",
        description: "amount input label",
        en: "How much would you like to donate?",
        fr: "Combien souhaitez-vous contribuer ?",
        de: "Wieviel möchten Sie spenden?"
    },
    {
        name: "fundraiseDonateStartPrimary",
        location: "fundraise donate start view",
        description: "primary button string",
        en: "Donate",
        fr: "Donner",
        de: "Spenden"
    },
    {
        name: "fundraiseDonateStartSecondary",
        location: "fundraise donate start view",
        description: "secondary button string",
        en: "Donate",
        fr: "Donner",
        de: "Spenden"
    },
    {
        name: "fundraiseDonateStartAlt",
        location: "fundraise donate start view",
        description: "alternative button string",
        en: "credit account",
        fr: "Créditer mon compte",
        de: "Meinem Konto gutschreiben"
    },
    {
        name: "fundraiseDonateStepTitle",
        location: "fundraise donate start view",
        description: "indication of beneficiary in title ",
        en: "Donate to: %%title%%",
        fr: "Donner à : %%title%%",
        de: "Spenden an: %%title%%"
    },
    {
        name: "fundraiseDonateStepAmountLabel",
        location: "fundraise donate start view",
        description: "amount field label ",
        en: "Amount",
        fr: "Montant",
        de: "Betrag"
    },
    {
        name: "fundraiseDonateStepAmountPhl",
        location: "fundraise donate start view",
        description: "amount field placeholder",
        en: "Amount",
        fr: "Montant",
        de: "Betrag"
    },
    {
        name: "fundraiseDonateStepAnonymous",
        location: "fundraise donate start view",
        description: "anoymous donation option",
        en: "I would like to make this donation anonymous",
        fr: "Je souhaite faire un don anonyme",
        de: "Ich möchte anonym spenden"
    },
    {//*
        name: "fundraiseDonateStepTermsConfrim",
        location: "fundraise donate start view",
        description: "confirm agreeing to terms of use",
        en: "I confirm I accept the terms of use",
        fr: "Je confirme accepter les conditions d'utilisation",
        de: "Ja, ich stimme den Nutzungsbedingungen zu"
    },
    {
        name: "fundraiseDonateStepPrimary",
        location: "fundraise donate start view",
        description: "donate to fundraise button -> submit",
        en: "Donate",
        fr: "Donner",
        de: "Spenden"
    },
    {
        name: "fundraiseDonatePayTitle",
        location: "fundraise donate pay view",
        description: "title",
        en: "Amount to donate: %%amount%%",
        fr: "Montant à donner : CHF %%amount%%",
        de: "Betrag der Spende wählen: CHF %%amount%%"
    },
    {
        name: "fundraiseDonatePayPrimary",
        location: "fundraise donate pay view",
        description: "primary button string",
        en: "Donate",
        fr: "Donner",
        de: "Spenden"
    },
    {
        name: "fundraiseUseCreditPayPrimary",
        location: "fundraise use credit pay view",
        description: "primary button string",
        en: "Donate",
        fr: "Donner",
        de: "Spenden"
    },
    {
        name: "fundraiseCreateTitleLabel",
        location: "fundraiser create new view",
        description: "title input label",
        en: "Choose a title for your fundraiser",
        fr: "Donner un titre à votre collecte de fond",
        de: "Titel"
    },
    {
        name: "fundraiseCreateTitleHolder",
        location: "fundraiser create new view",
        description: "title input label",
        en: "Title",
        fr: "Titre",
        de: "Titel"
    },
    {
        name: "fundraiseCreateReasonLabel",
        location: "fundraiser create new view",
        description: "reason input label",
        en: "Why would you like to fundraise? Birthday, marathon, emergency, etc.",
        fr: "Pour quelle raison faites-vous votre collecte ? Anniversaire, marathon, urgence humanitaire, etc.",
        de: "Warum möchten Sie Spenden sammeln? Geburtstag, Marathon, humanitären Krisen, usw."
    },
    {
        name: "fundraiseCreateReasonHolder",
        location: "fundraiser create new view",
        description: "reason input place holder",
        en: "Reason",
        fr: "Raison",
        de: "Beschreibung"
    },
    {
        name: "fundraiseCreateDestinationLabel",
        location: "fundraiser create new view",
        description: "destination input label",
        en: "Would you like to fundraise for your SwissDonations account or for a charity or business?",
        fr: "Souhaitez-vous faire une collecte pour votre compte SwissDonations ou pour une association ou une entreprise ?",
        de: "Möchten Sie für Ihr SwissDonations-Konto oder für eine Wohltätigkeitsorganisation oder ein Unternehmen Spenden sammeln?"
    }
    ,
    {
        name: "fundraiseCreateDestinationPlaceholder",
        location: "fundraiser create new view",
        description: "destination input placeholder",
        en: "Beneficiary",
        fr: "Bénéficiaire",
        de: "Empfänger"
    },
    {
        name: "fundraiseCreateStartLabel",
        location: "fundraiser create new view",
        description: "start date input label",
        en: "When would you like your fundraiser to start?",
        fr: "Date de début de la collecte",
        de: "Startdatum"
    },
    {
        name: "fundraiseCreateEndLabel",
        location: "fundraiser create new view",
        description: "end date input label",
        en: "When would you like it to stop?",
        fr: "Date de fin de la collecte",
        de: "Enddatum"
    },
    {
        name: "fundraiseCreateCalendarReset",
        location: "fundraiser create new view",
        description: "calendar reset",
        en: "clear",
        fr: "Annuler",
        de: "Löschen"
    },
    {
        name: "fundraiseCreateAmountLabel",
        location: "fundraiser create new view",
        description: "amount input label",
        en: "How much would you like to raise?",
        fr: "Combien souhaitez-vous collecter ?",
        de: "Wieviel möchten Sie sammeln?"
    },
    {
        name: "fundraiseCreateAmountLabelNotice",
        location: "fundraiser create new view",
        description: "amount input label",
        en: "* Set value to 0 if you do not wish to publish a target amount",
        fr: "* Définissez la valeur à 0 si vous ne souhaitez pas publier de montant cible",
        de: "* Setzen Sie den Wert auf 0, wenn Sie keinen Zielbetrag veröffentlichen möchten"
    },
    {
        name: "fundraiseCreateAmountPlaceholder",
        location: "fundraiser create new view",
        description: "amount input placeholder",
        en: "Amount",
        fr: "Montant",
        de: "Betrag"
    },
    {
        name: "fundraiseCreateDescriptionLabel",
        location: "fundraiser create new view",
        description: "fundraiser description label",
        en: "Briefly describe why you are fundraising",
        fr: "Expliquez en détail votre collecte et vos objectifs",
        de: "Beschriebung"
    },
    {
        name: "fundraiseCreateDescriptionPlaceholder",
        location: "fundraiser create new view",
        description: "fundraiser description placeholder",
        en: "Text",
        fr: "Texte",
        de: "Text"
    },
    {
        name: "fundraiseCreatePictureSelection",
        location: "fundraiser create new view",
        description: "fundraiser image selection",
        en: "Choose a picture",
        fr: "Choisir une photo",
        de: "Titelbild"
    },
    {
        name: "fundraisePageTitle",
        location: "fundraiser list and home page",
        description: "page /section title",
        en: "Fundraising",
        fr: "Collectes de fonds",
        de: "Spendenaktion"
    },
    {
        name: "fundraiseBtnText",
        location: "fundraiser list and home page",
        description: "page /section title",
        en: "All fundraisers",
        fr: "Toutes les Collecte de fonds",
        de: "Alle Spendenaktionen"
    },
    {
        name: "fundraiseEditPageTitle",
        location: "fundraiser edit",
        description: "page title",
        en: "Manage fundraiser",
        fr: "Gérer la collecte de fonds",
        de: "Die Spendenaktion verwalten"

    },
    {
        name: "stopFundraiserInfo",
        location: "fundraiser edit",
        description: "indication with link to go to stop fundraise section",
        en: "Edit the fundraiser here, or if you would like to stop the campaign",
        fr: "Modifiez la collecte de fonds ici, ou si vous souhaitez arrêter la campagne",
        de: "Bearbeiten Sie hier die Spendenaktion oder wenn Sie die fundraiser beenden möchten"

    },
    {
        name: "stopFundraiserIndicationButton",
        location: "fundraiser edit",
        description: "link text to go to stop section",
        en: "Click here",
        fr: "cliquez ici",
        de: "hier klicken"

    },
    {
        name: "fundraiseEditSectionTitle",
        location: "fundraiser edit",
        description: "edit section title",
        en: "Edit fundraiser",
        fr: "Modifier la collecte de fonds",
        de: "Fundraiser bearbeiten"

    },
    {
        name: "fundraiseEditStopSectionTitle",
        location: "fundraiser edit",
        description: "stop fundraiser section title ",
        en: "Stop fundraiser?",
        fr: "Arrêter la collecte de fonds ?",
        de: "Fundraiser beenden?"

    },
    {
        name: "fundraiseEditStopNotice",
        location: "fundraiser edit",
        description: "notice about stoping fundraisers ",
        en: "Warning: This action is definitive, however you will still be able to view its details.",
        fr: "Attention : Cette action est définitive, mais vous pourrez toujours consulter ses détails.",
        de: "Warnung: Diese Aktion ist endgültig, aber Sie können immer noch ihre Details einsehen."

    },
    {
        name: "fundraiseEditStopLabel",
        location: "fundraiser edit",
        description: "indication to type stop ",
        en: "Please type: STOP",
        fr: "Veuillez taper : STOP",
        de: "Bitte geben Sie ein: STOP"

    },
    {
        name: "fundraiseCreateLabel",
        location: "fundraiser create new view",
        description: "label to ask if the fundraiser should be private",
        en: "Would you like to make this fundraiser private?",
        fr: "Souhaitez-vous rendre cette collecte de fonds privée ?",
        de: "Möchten Sie diese Spendenaktion privat machen?"

    },
    {// new
        name: "fundraiseCreatePublishAsOrgLabel",
        location: "fundraiser create new view",
        description: "publish as organisation field label",
        en: "Publish as charity or Business",
        fr: "Publier en tant qu'association ou entreprise",
        de: "Veröffentlichen als Wohltätigkeitsorganisation oder Unternehmen"
    }
    ,
    {
        name: "fundraiseCreatePublishAsBusLabel",
        location: "fundraiser create new view",
        description: "publish as organisation field label",
        en: "Publish as business",
        fr: "Publier en tant qu'entreprise",
        de: "Als Unternehmen veröffentlichen"
    },
    {
        name: "fundraiseCreatePublishAsOrgNotice",
        location: "fundraiser create new view",
        description: "Notice",
        en: "Note: this option is only available to users that have requested adminstration access to an organisation and have been granted access by our team.",
        fr: "Remarque : cette option n'est disponible que pour les utilisateurs ayant demandé l'accès administrateur à une organisation et ayant été autorisés par notre équipe.",
        de: "Hinweis: Diese Option steht nur Benutzern zur Verfügung, die den Administrationszugriff auf eine Organisation angefordert und von unserem Team Zugang erhalten haben."

    },
    {
        name: "fundraiseCreatePrimary",
        location: "fundraiser create new view",
        description: "fundraiser primary button string",
        en: "Launch",
        fr: "Commencer",
        de: "Spendenaktion anlegen"
    },
    {
        name: "fundraiseCreateEditAlertProcessing",
        location: "fundraise create and edit views",
        description: "confirmation popup string ",
        en: "Please hold on, we are currently processing your request",
        fr: "Veuillez patienter, nous traitons votre requête.",
        de: "Bitte warten Sie, wir bearbeiten gerade Ihre Anfrage"
    },

    {
        name: "fundraiseCreateSuccess",
        location: "fundraiser create view",
        description: "confirmation popup string ",
        en: "Your fundraiser was successfully created",
        fr: "Votre collecte de fonds a été créée avec succès",
        de: "Ihre Spendenaktion wurde erfolgreich erstellt"
    },
    {
        name: "fundraiseEditSuccess",
        location: "fundraiser create view",
        description: "confirmation popup string ",
        en: "Your fundraiser was successfully updated",
        fr: "Votre collecte de fonds a été mise à jour avec succès",
        de: "Ihre Spendenaktion wurde erfolgreich aktualisiert."
    },
    {
        name: "fundraiseCreateEditError",
        location: "fundraise create edit view view",
        description: "confirmation popup string ",
        en: "Oops, something went wrong, please try again. If this issue persists, please help us improve our user experience by informing us about it.",
        fr: "Oops, une erreur est survenue, veuillez réessayer. Si le problème persiste, veuillez nous aider à améliorer notre expérience utilisateur en nous en informant.",
        de: "Ups, etwas ist schief gelaufen, bitte versuchen Sie es erneut.   Wenn dieses Problem weiterhin besteht, helfen Sie uns bitte, unsere Benutzerfreundlichkeit zu verbessern, indem Sie uns darüber informieren.",
    },
    {
        name: "fundraiserUrlCopyErr",
        location: "user fundraisers",
        description: "text indication that we could not copy the url",
        en: "Failed to copy to clipboard",
        fr: "Échec de la copie dans le presse-papiers",
        de: "Kopieren in die Zwischenablage fehlgeschlagen"
    },
    {
        name: "fundraiserUrlCopyWorked",
        location: "user fundraisers",
        description: "text indication that copied the url to clipboard",
        en: "Copied to clipboard",
        fr: "Copié dans le presse-papiers",
        de: "In die Zwischenablage kopiert"
    },
    {
        name: "loginPromtMessage",
        location: "fundraiser detail, organisation detail .., donatepage",
        description: "promt for user to login or create an account",
        en: "Please sign in or create an account to perfom this action.",
        fr: "Veuillez vous connecter ou créer un compte pour effectuer cette action.",
        de: "Bitte melden Sie sich an oder erstellen Sie ein Konto, um diese Aktion auszuführen."

    }
    ,
    {
        name: "loginTitle",
        location: "login page view",
        description: "title",
        en: "Connect to your SwissDonations account",
        fr: "Accédez à votre compte SwissDonations",
        de: "Zugang zu Ihrem SwissDonations Konto"
    },
    {
        name: "loginEmailLabel",
        location: "login page view",
        description: "email input label",
        en: "Your email:",
        fr: "Votre adresse e-mail",
        de: "E-Mail-Adresse"
    },
    {
        name: "loginEmailPlaceHolder",
        location: "login page view",
        description: "email input placehold",
        en: "Email:",
        fr: "E-mail",
        de: "E-Mail-Adresse"
    },
    {
        name: "loginEmailInvalidError",
        location: "login page view",
        description: "email input warning",
        en: "invalid email address",
        fr: "Adresse email erronée",
        de: "E-Mail-Adresse ungültig"
    },
    {
        name: "loginPasswordLabel",
        location: "login page view",
        description: "password input label",
        en: "password:",
        fr: "Mot de passe",
        de: "Passwort"
    },
    {
        name: "loginPasswordPlaceHolder",
        location: "login page view",
        description: "password input placeholder",
        en: "Password",
        fr: "Mot de passe",
        de: "Passwort"
    },
    {
        name: "loginNoAccount",
        location: "login page view",
        description: "alt action strings",
        en: "Don't have an account?",
        fr: "Vous n'avez pas de compte ?",
        de: "Du hast noch kein Profil?"
    },
    {
        name: "loginLostPassword",
        location: "login page view",
        description: "alt action strings",
        en: "or lost your password?",
        fr: " Mot de passe oublié ?",
        de: "Passwort vergessen?"
    },
    {//*** */ sur la page d'authentification
        name: "loginPrimary",
        location: "login page view",
        description: "primary button string",
        en: "Login",
        fr: "se connecter",
        de: "Anmelden"
    },
    {
        name: "loginError",
        location: "login page view",
        description: "primary button string",
        en: "Sorry, please verify your email address and password.",
        fr: "Désolé, Merci de vérifier votre adresse e-mail et mot de passe.",
        de: "Entschuldigung, bitte überprüfen Sie Ihre E-Mail-Adresse und Ihr Passwort."
    },
    {
        name: "signupTitle",
        location: "signup page view",
        description: "title",
        en: "Create your SwissDonations account",
        fr: "Créer un compte SwissDonations",
        de: "Registrieren"
    },
    {
        name: "completeInfoTitle",
        location: "Update info popup",
        description: "title",
        en: "Verify your SwissDonations profile",
        fr: "Vérifiez votre profil SwissDonations",
        de: "Überprüfen Sie Ihr SwissDonations-Profil"
    }
    , {
        name: "completeInfoBtnText",
        location: "Update info popup",
        description: "button text",
        en: "Update",
        fr: "Mettre à jour",
        de: "Aktualisieren"
    }
    ,
    {
        name: "signupFirstName",
        location: "signup page view",
        description: "first name input",
        en: "First Name:",
        fr: "Prénom",
        de: "Vorname"
    },
    {
        name: "signupFirstNamePlaceHolder",
        location: "signup page view",
        description: "first name input PlaceHolder",
        en: "First Name:",
        fr: "Prénom",
        de: "Vorname"
    },
    {
        name: "signupLastName",
        location: "signup page view",
        description: "last name input",
        en: "Last Name:",
        fr: "Nom :",
        de: "Nachname"
    },
    {
        name: "signupLastNamePlaceHolder",
        location: "signup page view",
        description: "last name input",
        en: "Last Name:",
        fr: "Nom :",
        de: "Nachname:"
    },
    {
        name: "signupDateOfBirth",
        location: "signup page view",
        description: "date of birth input",
        en: "Date of Birth:",
        fr: "Date de naissance :",
        de: "Geburtsdatum:"
    },
    {
        name: "signupEmailLabel",
        location: "signup page view",
        description: "email input label",
        en: "Email:",
        fr: "E-mail :",
        de: "E-Mail-Adresse:"
    },
    {//new
        name: "signupEmailVerificationLabel",
        location: "signup page view",
        description: "email input label",
        en: "Verify email:",
        fr: "Vérifier l'e-mail :",
        de: "E-Mail-Adresse bestätigen:"
    },
    {//new
        name: "signupEmailVerificationMismatchWarning",
        location: "signup page view",
        description: "email input label",
        en: "The emails your provided do not match:",
        fr: "Les adresses e-mail fournies ne correspondent pas :",
        de:"Die angegebenen E-Mails stimmen nicht überein:"
    },
    {
        name: "signupEmailPlaceHolder",
        location: "signup page view",
        description: "email input label",
        en: "Email:",
        fr: "E-mail :",
        de: "E-Mail-Adresse:"
    },
    {
        name: "signupPasswordLabel",
        location: "signup page view",
        description: "password input label",
        en: "Set your password:",
        fr: "Mot de passe",
        de: "Passwort"
    },
    {
        name: "signupPasswordPlaceHolder",
        location: "signup page view",
        description: "password input label",
        en: "Set your password:",
        fr: "Mot de passe",
        de: "Passwort"
    },
    {
        name: "signupStreet",
        location: "signup page view",
        description: "street input",
        en: "Street:",
        fr: "Rue:",
        de: "Straße:"
    },
    {
        name: "signupStreetNumber",
        location: "signup page view",
        description: "street number input",
        en: "Street Number:",
        fr: "Numéro de rue:",
        de: "Hausnummer:"
    },
    {
        name: "signupPostalCode",
        location: "signup page view",
        description: "postal code input",
        en: "Postal Code:",
        fr: "Code Postal:",
        de: "Postleitzahl:"
    },
    {
        name: "signupCountry",
        location: "signup page view",
        description: "country input",
        en: "Country:",
        fr: "Pays:",
        de: "Land:"
    },
    {
        name: "signupCity",
        location: "signup page view",
        description: "city input",
        en: "City:",
        fr: "Ville:",
        de: "Stadt:"
    }
    ,
    {//*
        name: "signupConditionConfirmationstring",
        location: "signup page view",
        description: "condition confirmation string",
        en: "By proceeding, I agree to the terms of use",
        fr: "Je confirme accepter les conditions d'utilisation",
        de: "Ja, ich stimme den Nutzungsbedingungen zu"
    },
    {
        name: "signupHasAccount",
        location: "signup page view",
        description: "alt action strings",
        en: "Already have an account?",
        fr: "Vous avez déjà un compte ?",
        de: "Sie haben bereits ein Konto?"
    },
    {
        name: "signupLostPassword",
        location: "signup page view",
        description: "alt action strings",
        en: " or ost your password?",
        fr: "Mot de passe oublié ?",
        de: "Passwort vergessen?"
    },
    {
        name: "signupPrimary",
        location: "signup page view",
        description: "primary button string",
        en: "create account",
        fr: "Créer un compte",
        de: "Registrieren"
    },
    {
        name: "signUpSuccess",
        location: "sign up page view",
        description: "success account creation notice",
        en: "Your account was created successfully, please sign in to proceed",
        fr: "avis de création de compte de réussite",
        de: "Ihr Konto wurde erfolgreich erstellt"
    },
    {
        name: "signUpRequireAgreement",
        location: "sign up page view",
        description: "user tried to submit with agreeing to terms fo use",
        en: "Your agreement to our terms of use is required to proceed",
        fr: "Votre acceptation de nos conditions d’utilisation est nécessaire pour procéder",
        de: "Ihre Zustimmung zu unseren Nutzungsbedingungen ist erforderlich, um fortzufahren"
    },
    {
        name: "requestNewPasswordTitle",
        location: "password reset request",
        description: "title",
        en: "Reset your SwissDonations password",
        fr: "Réinitialisez votre mot de passe SwissDonations",
        de: "Bitte setzen Sie Ihr SwissDonations Passwort zurück"
    },
    {
        name: "requestNewPasswordEmailLabel",
        location: "password reset request",
        description: "Email input label",
        en: "Your email",
        fr: "Votre E-mail",
        de: "E-Mail-Adresse"
    },
    {
        name: "requestNewPasswordEmailPlaceHolder",
        location: "password reset request ",
        description: "Email input placeholder",
        en: "email",
        fr: "e-mail",
        de: "E-Mail-Adresse"
    },
    {
        name: "requestNewPasswordprimaray",
        location: "password reset request",
        description: "primary action button",
        en: "Send me a reset code",
        fr: "Obtenir un code",
        de: "Einen neuen Code anfordern"
    },
    {
        name: "requestNewPasswordSecondary",
        location: "password reset request",
        description: "secondary action button",
        en: "Already have a code?",
        fr: "J'ai déjà un code ?",
        de: "Ich habe bereits einen Code"
    },
    {
        "name": "requestNewPasswordError",
        "location": "password reset request",
        "description": "secondary action button",
        "en": "Sorry, there seems to be something wrong with the email address you provided. Please verify it and try again.",
        "fr": "Désolé, il semble qu'il y ait un problème avec l'adresse e-mail que vous avez fournie. Veuillez la vérifier et réessayer.",
        "de": "Entschuldigung, es scheint ein Problem mit der von Ihnen angegebenen E-Mail-Adresse zu geben. Bitte überprüfen Sie sie und versuchen Sie es erneut."
    }
    ,
    {
        name: "requestNewPasswordInvalidEmail",
        location: "password reset request",
        description: "secondary action button",
        en: "Sorry, we have not found a profile with this e-mail.",
        fr: "Désolé, nous n'avons pas trouvé de profil correspondant à cet e-mail.",
        de: "Wir haben leider kein Profil mit dieser E-mail gefunden."
    },
    {
        name: "passwordResetTitle",
        location: "password reset",
        description: "title",
        en: "Set new password",
        fr: "Définissez votre nouveau mot de passe",
        de: "Passwort neu vergeben"
    },
    {
        name: "passwordResetCodeLabel",
        location: "password reset",
        description: "Reset code label",
        en: "Reset code",
        fr: "Code de réinitialisation",
        de: "Rücksetzungscode"
    },
    {
        name: "passwordResetCodePlaceHolder",
        location: "password reset",
        description: "Reset code placeholder",
        en: "Code",
        fr: "Code",
        de: "Rücksetzungscode"
    },
    {
        name: "passwordResetEmailLabel",
        location: "password reset",
        description: "email input label",
        en: "Your email",
        fr: "Votre adresse e-mail",
        de: "E-Mail-Adresse"
    },
    {
        name: "passwordResetEmailPlaceHolder",
        location: "password reset",
        description: "email input placeholder",
        en: "Your email",
        fr: "Votre adresse e-mail",
        de: "E-Mail-Adresse"
    },
    {
        name: "passwordResetPassword",
        location: "password reset",
        description: "password input label",
        en: "password",
        fr: "Mot de passe",
        de: "Passwort"
    },
    {
        name: "passwordResetPasswordPlaceHolder",
        location: "password reset",
        description: "password input placeholder",
        en: "Password",
        fr: "Mot de passe",
        de: "Passwort"
    },
    {
        name: "passwordResetConfirmPassword",
        location: "password reset",
        description: "confirm password input label",
        en: "Confirm password",
        fr: "Confirmez votre mot de passe",
        de: "Bestätigen Sie Ihr Passwort"
    },
    {
        name: "passwordResetConfirmPasswordPlaceHolder",
        location: "password reset",
        description: "confirm password placeholder",
        en: "Confirm password",
        fr: "Confirmez votre mot de passe",
        de: "Bestätigen Sie Ihr Passwort"
    },
    {
        name: "passwordResetPrimary",
        location: "password reset",
        description: "primary button string",
        en: "Set new password",
        fr: "Réinitialiser votre mot de passe",
        de: "Passwort neu vergeben"
    },
    {
        name: "passwordResetNewCod",
        location: "password reset",
        description: "request new code button string",
        en: "Request new code?",
        fr: "Demander un nouveau code",
        de: "Einen neuen Code anfordern"
    },
    {
        name: "useCreditNotificationSuccess",
        description: "success",
        en: "Transaction successful",
        fr: "Transaction réussie",
        de: "Transaktion erfolgreich"
    },
    {
        name: "useCreditNotificationFailure",
        description: "Failed:",
        en: "Transaction Failed",
        fr: "Transaction non aboutie",
        de: "Transaktion fehlgeschlagen"
    },
    {
        name: "useCreditProcessing",
        description: "Processing",
        en: "We are processing your request, please wait.",
        fr: "Nous sommes en train de finaliser votre transaction, veuillez patienter.",
        de: "Wir bearbeiten Ihre Anfrage, bitte warten Sie."
    },
    {
        name: "notFoundTitle",
        description: "404 page title",
        en: "404: Page not found",
        fr: "404: Page non trouvée",
        de: "404: Seite nicht gefunden"

    },
    {
        name: "notFoundText",
        description: "404 page text",
        en: "Sorry, we could not find a corresponding page for your request",
        fr: "Désolé, nous n’avons pas pu trouver de page correspondante pour votre demande",
        de: "Leider konnten wir keine entsprechende Seite für Ihre Anfrage finden"

    },
    {
        name: "notFoundPrimary",
        description: "404 button text",
        en: "Visit our home page",
        fr: "Visitez notre page d’accueil",
        de: "Besuchen Sie unsere Homepage"

    },
    {
        name: "donationSubscriptionWarning",
        description: "notice text for user when subscribing for monthly donation",
        en: "By selecting ‘monthly’, you are subscribing to a montly donation to the charity and you will be charged on the following day of each month.",
        fr: "En sélectionnant « mensuel », vous vous abonnez à un don mensuel à l’organisme de bienfaisance et vous serez facturé le jour à indiquer ci-dessous chaque mois.",
        de: "Wenn Sie 'Monatlich' auswählen, abonnieren Sie eine monatliche Spende an die Wohltätigkeitsorganisation und werden am folgenden Tag eines jeden Monats belastet."
    },
    {
        name: "homeSummaryMonths",
        description: "months to display on user's donation summary barchart",
        en: [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ],
        fr: [
            "jan", "fév", "mar", "avr", "mai", "juin", "juil", "aou", "sep", "oct", "nov", "dec"
        ],
        de: [
            "Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"
        ]
    },
    {
        name: "days",
        description: "expression <days> use to show time remaining",
        en: "Days",
        fr: "Jours",
        de: "Tage"
    },
    {// interest
        name: 'createBy',
        desctription: "",
        en: "Created by: ",
        fr: "Créée par : ",
        de: "Erstellt von: "
    },
    {
        name: "userTransactionDonation",
        description: "indication that transaction is a donation",
        en: "Donation",
        fr: "Don",
        de: "Spende"
    },
    {
        name: "userTransactionCredit",
        description: "indication that transaction is an account credit",
        en: "'Account crediting",
        fr: "'Ajout de crédits",
        de: "'Hinzufügung von Guthaben"
    },
    {
        name: "feedsViewMore",
        description: "button text to request more feeds",
        en: "More articles",
        fr: "Plus d'articles",
        de: "Mehr"
    },
    {
        name: "feedsRelatedArticles",
        description: "header text for sidebar showing related articles",
        en: "Related articles",
        fr: "Articles liés",
        de: "zugehörige Artikel"
    },
    {
        name: "feedsArticleSourceKey",
        description: "pre appended text organization name. eg. by : Greenpeace",
        en: "From",
        fr: "Par",
        de: "Aus"
    },
    {
        name: "feedsListPageTitle",
        description: "Title for all feed page",
        en: "All news",
        fr: "Toutes les nouvelles",
        de: "Alle Nachrichten"
    },
    {
        name: "HomeFeedsListTitle",
        description: "Title for news on home page",
        en: "Recent news",
        fr: "Dernières nouvelles",
        de: "Aktuelle Nachrichten"
    },
    {//
        name: "newsPublishDateTitle",
        description: "Published on text",
        en: "Published on",
        fr: "Publié le ",
        de: "Veröffentlicht am"
    },
    {
        name: "fundraiseEditDestinationLabel",
        description: "form label indiacting destination of donation",
        en: "Fundraising for",
        fr: "Collecte de fonds pour",
        de: "Spendensammeln für"
    },
    {
        name: "fundraiseEditDestinaitonNote",
        description: "Note indicating that the befeneciary of a fundraizer cannot be changed",
        en: "Note: Fundraiser beneficiary cannot be modified later",
        fr: "Veuillez noter que le bénéficiaire ne pourra pas être modifié plus tard",
        de: "Bitte beachten Sie, dass der Begünstigte später nicht mehr geändert werden kann."
    },
    {
        name: "fundraiseEditConfirmButton",
        description: "update button text",
        en: "Update",
        fr: "Mise à jour",
        de: "Aktualisierung"
    },
    {// interest
        name: "fundraiseDetailDestinationKey",
        description: "Indicate to whom the funds will go",
        en: "In favor of: ",
        fr: "En faveur de : ",
        de: "Zu Gunsten von: "
    },
    {
        name: "fundraiseDetailDestinationSw",
        description: "Indicate that fundraiser is in favor of users sw account",
        en: "My SwissDonations Account",
        fr: "Mon compte SwissDonations",
        de: "Mein SwissDonations Konto"
    },
    {
        // new
        name: "fundraiseDetailDestinationBus",
        description: "Indicates that the fundraiser is in favor of the user's SwissDonations account",
        en: "Above selected charity or business",
        fr: "L'association ou l'entreprise sélectionnée ci-dessus",
        de: "Die oben ausgewählte Wohltätigkeitsorganisation oder das Unternehmen"
    }
    ,
    {
        name: "fundraiseDetailMotivationKey",
        description: "announce the resean for the fundraizer",
        en: "Motivation: ",
        fr: "Motivation : ",
        de: "Motivation: "
    },
    {
        name: "fundraiseCreateEndStartDateWarning",
        description: "Warning for end date and start date relation",
        en: "Please make sure that the campaigns end date is after its start date",
        fr: "Veuillez vous assurer que la date de fin est après celle de début",
        de: "Bitte achten Sie darauf, dass das Enddatum nach dem Startdatum liegt."
    },
    {
        name: "fundraiseCreateTitleWarning",
        description: "missing title for fundraiser",
        en: "Please provide a title for your fundraiser",
        fr: "Veuillez donner un titre à votre collecte",
        de: "Bitte geben Sie einen Titel für Ihre Spendensammlung an"
    },
    {
        name: "fundraiserCreateReasonWarning",
        description: "missing purpose for fundraiser",
        en: "Please provide a reason for your fundraiser",
        fr: "Vuillez donner une raison à votre collecte",
        de: "Bitte geben Sie einen Grund für Ihre Spendensammlung an"
    },
    {
        name: "fundraiseCreateBeneficiaryWarning",
        description: "Warn user to pick beneficiary",
        en: "Please choose a fundraising beneficiary",
        fr: "Veuillez choisir un destinataire",
        de: "Bitte wählen Sie einen Begünstigten"
    },
    {
        name: "fundraiseCreateStartDateWarning",
        description: "missing starting date warning",
        en: "Please choose a starting date for your fundraiser",
        fr: "Veuillez choisir une date de début",
        de: "Bitte wählen Sie ein Startdatum"
    },
    {
        name: "fundraiseCreateEndDateWarning",
        description: "missing end date warning",
        en: "Please choose an end date for your fundraiser",
        fr: "Veuillez choisir une date de fin",
        de: "Bitte wählen Sie ein Enddatum"
    },
    {
        name: "fundraiseCreateTargetWarning",
        description: "missing target amount for fundraiser warning",
        en: "Please set your fundraise target",
        fr: "Veuillez définir votre objectif de collecte",
        de: "Bitte legen Sie Ihr Spendenziel fest"
    },
    {
        name: "fundraiseCreateDescriptionWarning",
        description: "missing description warning",
        en: "Please describe your project",
        fr: "Veuillez décrire votre projet",
        de: "Bitte beschreiben Sie Ihr Projekt"
    },
    {
        name: "fundraiseCreateImageWarning",
        description: "missing image for fundraiser warning",
        en: "Please choose an image",
        fr: "Veuillez choisir une image",
        de: "Bitte wählen Sie ein Bild"
    },
    {
        name: "transactionConfirmationSuccessTitle",
        description: "transaction success page title ",
        en: "Thank you for your transaction",
        fr: "Merci pour votre transaction.",
        de: "Vielen Dank für Ihre Transaktion."
    },
    {
        name: "transactionConfirmationSuccessBody",
        description: "transaction succes page body",
        en: "We have successfully registered your transaction, you will shortly recieve a confirmation e-mail.",
        fr: "Nous avons enregistré avec succès votre transaction. Vous recevrez bientôt un e-mail de confirmation.",
        de: "Wir haben Ihre Transaktion erfolgreich registriert. Sie erhalten in Kürze eine Bestätigungs-E-Mail."
    },
    {
        name: "transactionConfirmationFailureTitle",
        description: "transaction failure page title",
        en: "Transaction failure",
        fr: "Échec de la transaction",
        de: "Transaktionsfehler"
    },
    {
        name: "transactionConfirmationFailureBody",
        description: "transaction failure page body",
        en: "We failed to process your transaction, please try again or reach out to us for further assistance.",
        fr: "Nous n'avons pas réussi à traiter votre transaction, veuillez réessayer ou nous contacter pour obtenir une assistance supplémentaire.",
        de: "Wir konnten Ihre Transaktion nicht bearbeiten. Bitte versuchen Sie es erneut oder kontaktieren Sie uns für weitere Hilfe."
    },
    {
        name: "paymentDetailsTitle",
        en: "SwissDonations bank transfer details",
        fr: "Coordonnées bancaires de SwissDonations",
        de: "SwissDonations Bankverbindung"
    },
    {
        name: "paymentDetailsAccountholder"
        , en: {
            fieldName: "Account holder",
            text: "SwissDonations"
        }
        , fr: {
            fieldName: "Titulaire du compte",
            text: "SwissDonations"
        }
        , de: {
            fieldName: "Kontoinhaber",
            text: "SwissDonations"
        }
    },
    {
        name: "paymentDetailsAddress",
        en: {
            fieldName: "Address",
            text: "SwissDonations,\n ch. du Presbytère 5,\n 1294 Genthod"
        },
        fr: {
            fieldName: "Adresse",
            text: "SwissDonations,\n ch. du Presbytère 5,\n 1294 Genthod"
        },
        de: {
            fieldName: "Adresse",
            text: "SwissDonations,\n ch. du Presbytère 5,\n 1294 Genthod"
        }
    },
    {
        name: "paymentDetailsBankname",
        en: {
            fieldName: "Bank name",
            text: "Alternative Bank Schweiz AG"
        },
        fr: {
            fieldName: "Banque",
            text: "Alternative Bank Schweiz AG"
        },
        de: {
            fieldName: "Bankname",
            text: "Alternative Bank Schweiz AG"
        }
    },
    {
        name: "paymentDetailsBankaddress",
        en: {
            fieldName: "Bank address",
            text: "Rue du Port-Franc 11, \nC.P. 161, \n1003 Lausanne"
        },
        fr: {
            fieldName: "Adresse de la banque",
            text: "Rue du Port-Franc 11, \nC.P. 161, \n1003 Lausanne"
        },
        de: {
            fieldName: "Bankadress",
            text: "Rue du Port-Franc 11, \nC.P. 161, \n1003 Lausanne"
        }
    },
    {
        name: "paymentDetailsIban",
        en: {
            fieldName: "IBAN",
            text: "CH39 0839 0038 7188 1000 1"
        },
        fr: {
            fieldName: "IBAN",
            text: "CH39 0839 0038 7188 1000 1"
        },
        de: {
            fieldName: "IBAN",
            text: "CH39 0839 0038 7188 1000 1"
        }
    },
    {
        name: "paymentDetailsBic",
        en: {
            fieldName: "BIC (Swift)",
            text: "ABSOCH22XXX"
        },
        fr: {
            fieldName: "BIC (Swift)",
            text: "ABSOCH22XXX"
        },
        de: {
            fieldName: "BIC (Swift)",
            text: "ABSOCH22XXX"
        }
    },
    {
        name: "paymentDetailsIid",
        en: {
            fieldName: "IID (clearing number)",
            text: "8390"
        },
        fr: {
            fieldName: "IID (clearing number)",
            text: "8390"
        },
        de: {
            fieldName: "IID (clearing number)",
            text: "8390"
        }
    },
    {
        name: "imageRatioRecommendation",
        en: "You shall get the best appearance if your image has a 16x9 image ratio.",
        fr: "Vous obtiendrez la meilleure apparence si votre image a un rapport d'image de 16x9.",
        de: "Das beste Erscheinungsbild erhalten Sie, wenn Ihr Bild ein Seitenverhältnis von 16x9 hat."
    },
    {
        name: "autologgedOutTitle",
        en: "Security log out",
        fr: "Déconnexion de sécurité",
        de: "Sicherheitsabmeldung"
    },
    {
        name: "autologgedOutBody",
        en: "You were automatically logged out due to a lack of activity for more than 25 minutes.",
        fr: "Vous avez été déconnecté automatiquement en raison d'un manque d'activité pendant plus de 25 minutes.",
        de: "Sie wurden aufgrund mangelnder Aktivität für mehr als 25 Minuten automatisch abgemeldet."
    },
    {// new
        name: "giftCardTitle",
        description: "gift card page title",
        en: "Gift cards",
        fr: "Cartes cadeau",
        de: "Geschenkkarten"
    },
    {
        // new
        name: "giftCardTitleOrg",
        description: "gift card page title",
        en: "Gift cards for Charities",
        fr: "Cartes cadeaux pour associations",
        de: "Geschenkkarten für Wohltätigkeitsorganisationen"
    }
    ,
    {// new
        name: "giftCardBeneficiaryInfoLabel",
        description: "gift card beneficiary info label",
        en: "Gift card beneficiary information",
        fr: "Informations sur le bénéficiaire de la carte cadeau",
        de: "Informationen zum Empfängers der Geschenkkarte"
    },
    {// new
        name: "giftCardBeneficiaryNamePlaceholder",
        description: "gift card beneficiary name placeholder text",
        en: "Last name",
        fr: "Nom de famille",
        de: "Nachname"
    },
    {// new
        name: "giftCardBeneficiaryFirstNamePlaceholder",
        description: "gift card beneficiary first name placeholder text",
        en: "First name",
        fr: "Prénom",
        de: "Vorname"
    },
    {// new
        name: "giftCardBeneficiaryEmailPlaceholder",
        description: "gift card beneficiary email placeholder text",
        en: "Email",
        fr: "E-mail",
        de: "E-Mail"
    },
    {// new
        name: "giftCardMessageLabel",
        description: "gift card message label text",
        en: "Would you like to send a message with your gift card?",
        fr: "Souhaitez-vous transmettre un message avec votre carte cadeau ?",
        de: "Möchten Sie eine Nachricht mit Ihrer Geschenkkarte versenden?"
    },
    {// new
        name: "giftCardMessagePlaceholder",
        description: "gift card message placeholder text",
        en: "Message",
        fr: "Message",
        de: "Nachricht"
    },
    {// new
        name: "giftCardDonateButton",
        description: "gift card donate button text",
        en: "Donate",
        fr: "Donner",
        de: "Spenden"
    },
    {// new
        name: "giftCardSignUpButton",
        description: "gift card sign up button text",
        en: "Sign up",
        fr: "Créer un compte",
        de: "Registrieren"
    },
    {// new
        name: "giftCardSignInButton",
        description: "gift card sign in button text",
        en: "Sign in",
        fr: "Se connecter",
        de: "Anmelden"
    },
    {//new
        name: "giftFormPageTitle",
        description: "gift form page title",
        en: "Send a gift card",
        fr: "Envoyer une carte cadeau",
        de: "Senden Sie eine Geschenkkarte"
    },
    {//new
        name: "giftFormPageAmountLabel",
        description: "gift form page amount label",
        en: "How much would you like to gift?",
        fr: "Combien souhaitez-vous offrir ?",
        de: "Wie viel möchten Sie verschenken?"
    },
    {//new
        name: "giftFormPageSubtotalText",
        description: "gift form page subtotal text",
        en: "Subtotal with fees",
        fr: "Sous-total avec frais",
        de: "Zwischensumme mit Gebühren"
    },
    {//new
        name: "giftFormPageFeeText",
        description: "gift form page fee text",
        en: "Fee",
        fr: "Frais",
        de: "Gebühr"
    }, {
        name: "giftTitle",
        description: "gift title",
        en: "Gift Card",
        fr: "Carte cadeau",
        de: "Geschenkkarte"

    }
    , {//new
        name: "siteLoggedAlready",
        description: "Text indicating to the user they are arleady logged in",
        en: "You are already logged.",
        fr: "Vous êtes déjà connecté.",
        de: "Sie sind bereits angemeldet."

    }
    , {//new
        name: "siteToMyAccount",
        description: "gift title",
        en: "To my account",
        fr: "À mon compte",
        de: "Auf mein Konto"
    }
    ,
    {//new
        name: "newsLetterFormTitle",
        description: "",
        en: "Join our mailing list",
        fr: "Rejoignez notre liste de diffusion",
        de: "Treten Sie unserer Mailingliste bei"
    },
    {//new
        name: "newLetterSuccessMessage",
        description: "message to display upon success",
        en: "Thank you for joining our mailing list.",
        fr: "Merci de vous être inscrit à notre liste de diffusion.",
        de: "Vielen Dank, dass Sie sich unserer Mailingliste angeschlossen haben."
    },
    {//new
        name: "newsLetterEmailInputPlaceholder",
        description: "input placeholder text/label",
        en: "email",
        fr: "e-mail",
        de: "E-Mail"
    },
    {//new
        name: "newsLetterSubmitButton",
        description: "Button text",
        en: "Sign up",
        fr: "S'inscrire",
        de: "Anmelden"
    },
    {//new
        name: "newLetterFailMessage",
        description: "message to display upon submission failure",
        en: "Something went wrong, please try again.",
        fr: "Une erreur s'est produite, veuillez réessayer.",
        de: "Etwas ist schiefgelaufen, bitte versuchen Sie es erneut."
    }


];

export default translationCopy;